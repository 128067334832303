@import '@src/styles/colors.scss';

.LoadingBlock {
  display: flex;
  flex-direction: column;
  height: 500px;
  width: 100%;
  min-width: 100%;
  justify-content: center;
  align-items: center;
}

.LoadingText {
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: $CloudBurst;
}

.LoadingPoints {
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-bottom: 30px;

  & > div {
    width: 11px;
    height: 11px;
    background-color: $CloudBurst;
    border-radius: 50%;
    margin: 0px 5px;
    transition: background-color 200ms;
    animation: dot-keyframes 2s infinite ease-in-out;

    &:nth-child(1n) {
      animation-delay: .3s;
    }

    &:nth-child(2n) {
      animation-delay: .6s;
    }

    &:nth-child(3n) {
      animation-delay: .9s;
    }

    &:nth-child(4n) {
      animation-delay: 1.2s;
    }
  }
}

@keyframes dot-keyframes {
  0% {
    opacity: .4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: .4;
    transform: scale(1, 1);
  }
}
