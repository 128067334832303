@import '@src/styles/windows.scss';
@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';

.Component-Profile-Layout-Left-Menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  height: max-content;
  box-sizing: border-box;
  position: -webkit-sticky;
  position: sticky;
  top: 74px;
  left: 0;
  padding: 0 14px;
  width: 74px;
  z-index: 2;
  margin-bottom: 50px;

  @include tablet {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #FFFFFF;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    padding: 30px 60px;
    box-sizing: border-box;
    height: auto;
    top: auto;
    z-index: 15;
    margin-bottom: 0;
  }

  @include mobile {
    padding: 12px 24px;
  }

  &-Link, &-Tree {
    cursor: pointer;

    a {
      color: $CasperLight;
    }

    .TreeHeaderContainer, .LinkContainer {
      margin-top: 8px;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      @include tablet {
        margin-top: 0;
      }

      .Icon {
        margin-right: 12px;

        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $WhiteLight;
        color: #7A8091;

        min-width: 54px;
        height: 54px;
        box-sizing: border-box;
        border: 0.5px solid $CasperLight;
        border-radius: 10px;

        svg {
          width: 18.7px;
        }
        img {
          width: 18.7px;
        }

        @include tablet {
          margin-right: 0;
          border: none;
          background-color: inherit;

          img {
            min-width: 50px;
            height: 50px;
          }
          svg {
            width: 50px;
            height: 50px;
          }
        }

        @include mobile{
          width: auto;
          height: auto;

          img {
            min-width: 24px;
            height: 24px;
          }
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      .Label {
        white-space: nowrap;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: $Waterloo;

        @include tablet {
          display: none;
        }
      }
    }

    &_isOpened .TreeHeaderContainer,
    &_isActive > .LinkContainer {

      & > .Label {
        font-weight: bold;

        @include tablet {
          font-weight: normal;
          color: $CloudBurst;
        }
      }
      .Icon {
        //background-color: $Botticelli;

        @include tablet {
          background-color: inherit;
          color: #1C2A40;
        }
      }
    }

    .Submenu {

      .Icon {
        background-color: $CatskillWhite2;
      }
    }

    &_isActive > .LinkContainer {

      .Icon {
        background-color: $Botticelli;

        @include tablet {
          background-color: inherit;
          color: #1C2A40;
        }
      }
    }

    .LinkContainer.Disabled, .TreeHeaderContainer.Disabled {
      cursor: default;

      @include tablet {
        opacity: 0.5;
      }

      .Icon {
        background-color: $CatskillWhite2;

        @include tablet {
          background-color: inherit;
        }

        img {
          filter: brightness(5);

          @include tablet {
            filter: none;
          }
        }
      }

      .Label {
        color: #bec1c9;

        @include tablet {
          color: inherit;
        }
      }
    }

    &_id_add_contract {

      .LinkContainer {

        .Icon {
          background-color: $FrenchRose;
          color: #FFFFFF;

          @include tablet {
            background-color: inherit;
            color: $FrenchRose;
          }

          img {
            filter: brightness(0) invert(1);

            @include tablet {
              filter: none;
            }
          }
        }

        .Label {
          @include tablet {
            color: $FrenchRose;
          }
        }
      }
    }
  }

  .Label {
    transition: opacity 0.3s;
  }

  .Submenu {
    transition: margin-left 0.3s;
    display: none;

    &.isOpened {
      display: block;
    }

    @include tablet {
      position: absolute;
      bottom: 144px;
      flex-direction: column;
      left: 0;
      width: 100%;
      box-sizing: border-box;
      height: calc(100vh - (144px + 330px));
      background-color: #F2F4F9;
      overflow: auto;
      justify-content: end;

      &.isOpened {
        display: flex;
      }
    }

    @include mobile {
      bottom: 66px;
      height: 100dvh;
      padding-left: 23px;
      padding-right: 23px;
    }
  }

  &_state {

    &_open {
      background-color: transparent;

      @include tablet {
        background-color: #FFFFFF;
      }

      .Arrow {
        opacity: 1;
      }

      .Label {
        opacity: 1;
      }

      .Submenu {
        margin-left: 22px;

        @include tablet {
          margin-left: 0;
        }
      }
    }

    &_close {

      .Arrow {
        opacity: 0;
      }

      .Label {
        opacity: 0;
      }

      .Submenu {
        margin-left: 0px;
      }
    }
  }

  .Arrow {
    position: relative;
    transition: opacity 0.3s;
    margin-left: 10px;
    width: 4px;
    height: 4px;
    box-sizing: border-box;

    @include tablet {
      display: none;
    }

    &-Top {
      top: 2px;
      left: -1px;
      transform: rotate(-45deg);
    }

    &-Bottom {
      top: -1px;
      transform: rotate(135deg);
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      border-width: 1.3px 1.3px 0 0;
      border-style: solid;
      border-color: $CloudBurst;
      display: block;
    }
  }

  &-Copyright {
    position: fixed;
    bottom: 40px;
    left: 15px;
    transform-origin: center center;
    transform: rotate(180deg);

    writing-mode: vertical-lr;
    text-orientation: mixed;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: $Waterloo;

    @include tablet {
      display: none;
    }
  }

  &_isMenuLocked{
    active{
      .Icon{
        background-color: #C9D5E3 !important;
      }
    }
    svg{
      color: #7A8090;
    }
    @include tablet{
      position: fixed;
    }
    &:before{
      content: '';
      position: absolute;
      z-index: 5;
      width: 100%;
      height: 100%;
      background-color: transparent;
      top: 0;
      left: 0;
    }
  }

  &_isHelpFourthScreen {
    z-index: 1010!important;
    &:before{
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: transparent;
    }

    .Label {
      color: $WhiteDark!important;
    }

    .Arrow:before {
      border-color: $WhiteDark!important;
    }
    .Submenu{
      display: none !important;
    }
  }

  &-HelpMessage {
    display: flex;
    flex-direction: row;
    position: absolute;
    text-align: left;

    font-family: Flow, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    color: $CasperLight;

    &_First {
      top: 70px;
      left: 250px;
      width: 326px;

      svg {
        min-width: 87px;
        margin-right: 10px;
      }
    }

    &_Second {
      align-items: flex-end;
      top: 160px;
      left: 240px;
      width: 313px;

      span{
        position: absolute;
        top: -5px;
        right: -45px;
        width: 227px;
      }

      svg {
        min-width: 110px;
        margin-right: 10px;
      }
    }

    &_Third {
      align-items: flex-end;
      left: 100px;
      width: 480px;
      top: 450px;

      svg {
        min-width: 110px;
        margin-right: 10px;
      }
    }
  }
}
.Component-Profile-Layout-Left-Menu-Tree .Submenu .Component-Profile-Layout-Left-Menu-Link .LinkContainer .Label {
  @include tablet {
    display: block;
    opacity: 1;
    margin-left: 12px;
    @include text28
  }
  @include mobile {
    @include text13
  }
}
.Component-Profile-Layout-Left-Menu-Tree .Submenu .Component-Profile-Layout-Left-Menu-Link .LinkContainer .Icon {
  @include tablet {
    border: 1px solid $Casper;
    width: 90px;
    height: 90px;

    img {
      width: 33px;
    }
  }
  @include mobile {
    width: 54px;
    height: 54px;

    img {
      width: 16px;
    }
  }
}
.Component-Profile-Layout-Left-Menu-Tree .Submenu .Component-Profile-Layout-Left-Menu-Link {
  &:last-child {
    .LinkContainer {
      margin-bottom: 0;
    }
  }
}

.Component-Profile-Layout-Left-Menu-Tree .Submenu .Component-Profile-Layout-Left-Menu-Link .LinkContainer {
  @include tablet {
    margin-bottom: 12px;
  }
}
.Submenu {
  @include tablet {
    padding: 28px 72px;
  }
}
.Component-Profile-Layout-Left-Menu-Tree .TreeHeaderContainer,
.Component-Profile-Layout-Left-Menu > .Component-Profile-Layout-Left-Menu-Link > .LinkContainer {
  @include tablet {
    display: flex;
    flex-direction: column;
  }
}
.Component-Profile-Layout-Left-Menu-Tree .TreeHeaderContainer .Label,
.Component-Profile-Layout-Left-Menu > .Component-Profile-Layout-Left-Menu-Link .LinkContainer .Label {
  @include tablet {
    display: block;
    @include text19;
    opacity: 1;
    margin-top: 13px;
    font-weight: normal;
  }
  @include mobile {
    @include text10;
    margin-top: 6px;
    line-height: normal;
  }
}
.submenu_container {
  @include tablet {
    overflow: auto;
  }
}
.Component-Profile-Layout-Left-Menu-Tree_isOpened {
  color: #1C2A40;
}

.submenu_title {
  display: none;

  @include tablet {
    display: block;
    @include title52Bold();
    margin-bottom: 32px;
  }

  @include mobile {
    @include title24Bold;
    margin-bottom: 14px;
  }
}

.Component-Profile-Layout-Left-Menu_isMenuLocked{
  .Component-Profile-Layout-Left-Menu-Link_id_add_contract{
    .Icon{
      background-color: #C9D5E3 !important;
      @include mobile{
        background-color: initial !important;
      }
    }
    .Label{
      @include mobile{
        color: #7A8091 !important;
      }
    }
  }
}
