@import "@src/styles/colors.scss";

.getForFreeButton {
  display: flex;
  width: 100vw;
  height: 100%;
  justify-content: center;
  align-items: center;

  background: $Error;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 34px;
  color: $WhiteLight;

  @media screen and (max-width: 600px) {
    font-size: 38px;
  }

  @media screen and (max-width: 450px) {
    font-size: 32px;
  }
}