@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/functions.scss';
@import '@src/styles/windows.scss';

.container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 30px);
  max-width: 1170px;
  padding: 110px 15px 40px;
  position: relative;
  gap: 80px;

  @include mobile {
    overflow: hidden;
    max-width: 100%;
    padding: 33px 16px;
    gap: 0;
  }
}

.fastRegister {
  display: none;

  @include mobile {
    display: block;
    margin-bottom: 37px;
    @include text18Bold;
    color: $CloudBurst;
  }
}
