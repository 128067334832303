@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/functions.scss';

.mobile-menu {
  position: fixed;
  top: 0;
  bottom: scaledPixels(150);
  width: 100%;
  background-color: $WhiteLilac;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: scaledPixels(70);
    z-index: 1000;
    width: calc(100% - calc(140 * 100vw) / 720);

    .Button {
      font-size: scaledPixels(24);
      line-height: scaledPixels(29);
      padding: scaledPixels(15) scaledPixels(40);
      border-radius: scaledPixels(150);

      .icon {
        height: scaledPixels(60);
        width: scaledPixels(60);

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .Button_status_default {
      background-color: transparent;
      padding: 0;
    }
  }

  &__tags {
    width: calc(100% - calc(140 * 100vw) / 720);
    margin: scaledPixels(45) scaledPixels(70);
    padding: 0;
  }

  &__item {
    list-style-type: none;
    color: $CloudBurst;
    padding-top: scaledPixels(30);
    padding-bottom: scaledPixels(35);
    border-bottom: 1px solid $CasperLight;

    .Button {
      background-color: transparent;
      color: $CloudBurst;
      padding: 0;
      font-size: scaledPixels(44);
      font-weight: 800;
      line-height: scaledPixels(53);
      align-items: flex-start;
      border-radius: 0;
      width: 100%;

      .Button-Content {
        text-align: inherit;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        .icon {
          height: scaledPixels(25);
          width: scaledPixels(25);

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  &__link {
    font-size: scaledPixels(44);
    font-weight: 800;
    line-height: scaledPixels(53);
  }

  &__sub-tags {
    padding: 0;
    overflow: hidden;
    max-height: 0;

    &_show {
      max-height: scaledPixels(500);
      transition: max-height 0.5s ease-out;
      border-top: 1px solid $CasperLight;
      margin-top: scaledPixels(35);
    }
  }

  &__icon_show {
    transform: rotate(45deg);
    transition: transform 0.5s;
  }

  &__sub-item:last-child {
    margin-bottom: 0;
  }

  &__text {
    font-size: scaledPixels(44);
    font-weight: 800;
    line-height: scaledPixels(53);
  }

  &__sub-item {
    list-style-type: none;
    color: $Waterloo;
    margin: scaledPixels(44) scaledPixels(35);

    a, p {
      font-weight: 600;
      font-size: scaledPixels(30);
      line-height: scaledPixels(36);
      color: $Waterloo;
    }
  }
}
