@import '@src/styles/colors.scss';

.Loading {
  @keyframes rotate {

    to {
      transform: rotate(360deg);
    }
  }

  &-Container {
    animation: 2s rotate linear infinite;
  }
}
