@import '@src/styles/colors.scss';
@import '@src/styles/windows.scss';

.headerWrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @include tablet{
    width: 300px;
  }
  @include mobile{
    width: 150px;
  }
}

.LoginHeaderBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 290px;
  margin-bottom: 56px;
  @include tablet{
    margin-bottom: 36px;
    width: 90%;
  }

  .headerLogo {
    height: 65px;
  }

  a, img {
    height: 100%;
    @include tablet{
      width: 150px;
      height: 33px;
    }
    @include mobile{
      width: 150px;
      height: 33px;
    }
  }

  h1 {
    font-weight: 800;
    font-size: 52px;
    line-height: 62px;
    color: $WhiteLight;
    position: relative;
    @include tablet{
      font-size: 61px;
    }
    @include mobile{
      font-size: 30px;
      line-height: 36px;
    }
  }
}

.closeButton{
  display: none;
  @include tablet{
    display: block;
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
    margin-top: 15px;

    &:before{
      content: '';
      position: absolute;
      width: 20px;
      height: 1px;
      background-color: #ffffff;
      transform: rotate(45deg);
      top: 0;
      left: 0;
    }

    &:after{
      content: '';
      position: absolute;
      width: 20px;
      height: 1px;
      background-color: #ffffff;
      transform: rotate(-45deg);
      top: 0;
      left: 0;
    }
  }
}
