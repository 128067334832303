.loanLimitProgressBar {
  max-width: 940px;
  width: calc(100% - 360px);
  margin: 16px 0 0 0;
  @media screen and (max-width: 1080px) {
    margin: -2px 30px 25px;
    width: calc(100% - 60px);
  }
}

.emptyBar {
  position: relative;
  background: linear-gradient(to right, #F66082 var(--fill-percentage), #E3E7F1 var(--fill-percentage));
  border-radius: 5px;
  height: 10px;
  overflow: hidden;
}

.description {
  margin-top: 6px;
  text-align: center;
  font-size: 13px;
  color: #929AB2;
  @media screen and (max-width: 1080px) {
    font-size: 12px;
  }
}
