@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/functions.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  background-color: $Bordo;
  height: 40px;
  font-size: 16px;
  font-weight: normal;
  width: 328px;
  margin-top: 40px;
}

.component-support {
  display: flex;
  gap: 40px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: fit-content;
  max-width: 1170px;

  &_title {
      @include title42Black;
      color: $CloudBurst;

      @include mobile {
        display: none;
      }
  }

  &_mobile {
    display: none;
  }

  h2 {
    font-weight: 800;
    font-size: 60px;
    line-height: 72px;
    color: $CloudBurst;

    @media (max-width: 1300px) {
      font-weight: 800;
      font-size: 50px;
      line-height: 60px;
    }

    @media (max-width: 720px) {
      font-weight: 800;
      font-size: 24px;
      line-height: 29px;
    }
  }

  @include media(720px) {
    min-height: auto;
    display: none;

    &_mobile {
      display: flex;
      border-top: 1px solid $CasperLight;
      border-bottom: 1px solid $CasperLight;
      padding: 0;

      h2 {
        font-weight: 800;
        font-size: 24px;
        line-height: 29px;
      }
    }
  }
}
