@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/functions.scss';
@import '@src/styles/windows.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @include mobile {
    margin-bottom: 37px;
    gap: 16px;
  }
}

.upBlock {
  width: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0;
  justify-content: space-between;

  @media screen and (max-width: 1100px) {
    gap: 16px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.title {
  @include title42Black;
  color: $CloudBurst;

  @include mobile {
    @include text28Bold;
  }
}

.titleDescription {
  margin-bottom: 32px;

  @include mobile {
    margin-bottom: 10px;
  }
}

.grayTitle {
  @include text15Thin;
  color: $GullGray;
  margin-bottom: 2px;
}

.amountDescription {
  @include title28;
  color: $CloudBurst;
}

.additionalDescription {
  @include text14;
  font-weight: normal !important;
  color: $GullGray;
  margin-top: var(--top);

  @include mobile {
    margin-top: 0;
  }
}

.moreAboutProductBlock {
  display: none;
  align-items: center;
  gap: 6px;
  margin-bottom: 22px;

  @include mobile {
    display: flex;
  }
}

.moreAboutProductTitle {
  @include text16Bold;
  color: $CloudBurst;
}

.moreAboutProductButton {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: $LinkWater2;
  display: flex;
  align-items: center;
  justify-content: center;
  @include text19Bold;
  cursor: pointer;
}

.getPackageButton {
  background-color: $Bordo;
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: normal;
}

.getPackageButtonMobile {
  @extend .getPackageButton;
  display: none;

  @include mobile {
    display: flex;
  }
}

.leftButtonsBlock {
  display: flex;
  align-items: center;
  gap: 16px;
  width: auto;

  @media screen and (max-width: 1100px) {
    width: inherit;
    overflow: auto;
  }
}

.downBlock {
  display: flex;
  width: 100%;
  height: 425px;
  border-radius: 10px;
  overflow: hidden;

  @include mobile {
    height: 456px;
  }
}

.downBlockLeft {
  width: 328px;
  background-color: $CatskillWhite2;
  box-sizing: border-box;
  padding: 28px 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  z-index: 1;

  @include mobile {
    width: 100%;
    display: var(--display);
    height: 100%;
  }
}

.downBlockRight {
  width: calc(100% - 318px);
  margin-left: -10px;
  background-color: $CloudBurst;
  box-sizing: border-box;
  padding: 28px 63px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include tablet {
    padding: 16px 20px 16px 25px;
  }

  @include mobile {
    padding: 20px 16px 22px;
    display: var(--display);
    width: 100%;
    margin-left: 0;
    height: 100%;
  }
}

.downBlockRightTitleContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.downBlockRightTitleBlock {
  width: inherit;
}

.downBlockRightTitleProd {
  display: none;
  @include text14;
  color: $GullGray;

  @include mobile {
    display: block;
  }
}

.downBlockRightTitle {
  @include title28Bold;
  color: $WhiteLight;
  width: inherit;
  text-align: center;

  @include mobile {
    text-align: left;
  }
}

.downBlockRightButton {
  min-width: 30px;
  width: 30px;
  height: 30px;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: $LinkWater2;
  cursor: pointer;

  @include mobile {
    display: flex;
  }
}

.scheduleBlock {
  margin-top: -60px;
  width: 100%;
  height: 180px;
  position: relative;
  display: flex;
  align-items: flex-end;

  @include mobile {
    margin-top: 0;
    height: 107px;
  }
}

.verticalArrow {
  margin-top: 13px;
  height: 209px;
  width: 1px;
  background: $WhiteDark;
  position: absolute;
  left: 39px;
  top: 3px;

  @include mobile {
    height: 136px;
    left: 8px;
    top: -20px;
  }
}

.arrowHood {
  position: absolute;
}

.arrowHoodUp {
  top: -3.9px;
  left: -6.4px;
}

.horizontalArrow {
  width: 100%;
  height: 1px;
  background: $WhiteDark;
  position: absolute;
  bottom: 0;
}

.arrowHoodRight {
  top: -4px;
  right: -5px;
  transform: rotate(90deg);
}

.scheduleColumnsBlock {
  height: 140px;
  width: max-content;
  display: flex;
  gap: 27px;
  margin-left: 39px;
  padding-left: 27px;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 8px;
  margin-bottom: -14px;

  &::-webkit-scrollbar {
    width: 10px;
    height: 6px;

    @include mobile {
      height: 0;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(253, 253, 253, .2);
    border-radius: 10px;
  }

  @include mobile {
    margin-bottom: -8px;
    height: 83px;
    margin-left: 8px;
    padding-left: 26px;
  }
}

.scheduleColumnBlock {
  width: 27px;
  min-width: 27px;
  border: 1px solid $WhiteDark;
  background: rgba(253, 253, 253, .2);
}

.scheduleDescription {
  min-height: 78px;
  text-align: justify;
  @include text15Thin;
  color: $WhiteLight;

  @include mobile {
    font-weight: normal !important;
    @include text14;
  }
}

.button {
  width: 160px;
  height: 40px;

  @include mobile {
    height: 32px;
  }
}
