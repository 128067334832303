@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/functions.scss';

.page-support {

  &__title-mobile {
    $rootPath: &;
    display: flex;
    flex-direction: row;
    margin-bottom: scaledPixels(30);
    justify-content: space-between;
    align-items: center;
    padding: scaledPixels(36) 24px scaledPixels(18);

    h2 {
      margin-right: scaledPixels(20);
      margin-bottom: 0;
    }

    &__icon {
      width: scaledPixels(27);
      height: scaledPixels(19);
      transition: .3s;

      @at-root #{$rootPath}_opened & {
        transform: rotate(180deg)
      }
    }
  }

  &__question {

    &__content {

      &_mobile {
        padding-top: scaledPixels(30);
      }
    }

    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: $CloudBurst;

      @media screen and (max-width: 720px) {
        font-weight: 700;
        font-size: 25px;
        line-height: 30px;
      }
    }

    @include media(720px) {
      padding-top: scaledPixels(35);
      padding-bottom: scaledPixels(35);
      border-top: 0.5px solid $CasperLight;
      border-bottom: none;

      &__content {

        &__slider {
          font-size: scaledPixels(25);
          line-height: scaledPixels(30);

          &_intro {
            margin-bottom: scaledPixels(50);
            padding: 0 24px 0;
          }
        }
      }
    }
  }
}
