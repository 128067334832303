@import '@src/styles/colors.scss';
@import '@src/styles/windows.scss';

.Layout-Main {

  &-Content-Block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}