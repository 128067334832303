@import '@src/styles/colors.scss';
@import '@src/styles/windows.scss';

.MailingPage {
  display: flex;
  flex-direction: column;
  background: conic-gradient(from 90deg at 0% 105%, $WhiteBase, $CloudBurst);
  min-height: 100vh;
  @include tablet {
    padding: 0;
    background: #1C2A40;
  }
  @include mobile {
    min-height: 100dvh;
  }
}

.Content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  @include mobile {
    width: 100%;
  }
}

.InfoBlock {
  display: flex;
  flex-direction: column;
  width: 95%;
  @include tablet{
    width: 90%;
  }
}

.Title {
  font-size: 32px;
  font-weight: 600;
}

.Text {
  font-size: 14px;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 24px;

  @include tablet{
    margin-top: 10px;
    margin-bottom: 16px;
  }
  @include mobile{
    margin-top: 10px;
    margin-bottom: 16px;
  }

}

.Padding {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 30px 19px 30px;
  box-sizing: border-box;
  background: $WhiteLilac;
  border-radius: 10px;
  width: 450px;
  @include tablet{
    align-items: center;
    justify-content: center;
  }
  @include mobile{
    padding: 30px 30px 19px 30px;
    width: 100%;
  }

  button{
    width: 100%;
    border-radius: 6px;
  }

  >div{
    width: 100%;
  }
}

.AuthFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 105%;

  @include tablet{
    width: 100%;
  }

  span {
    font-size: 14px;
    line-height: 17px;
    color: $WhiteLight;
    @include tablet{
      font-size: 24px;
    }
    @include mobile{
      font-size: 14px;
    }
  }

  button {
    font-weight: 400;
    margin-right: 8px;
    @include tablet{
      height: 54px;
      font-size: 24px;
    }
    @include mobile{
      height: 32px;
      font-size: 14px;
    }
  }
}


.DivLine {
  width: 1px;
  background-color: $WhiteBase;
  height: 100%;
  @include tablet {
    display: none;
  }
}

.AuthBlock {
  overflow: hidden;

  .AuthForm {
    overflow: hidden;
    transition: max-height 500ms, margin-bottom 500ms;
    margin-bottom: 42px;
    @include mobile{
      margin-bottom: 36px;
    }
  }

  .InputWrapper {
    width: 100%;
  }
}

