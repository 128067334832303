@import '@src/styles/colors.scss';
@import '@src/styles/windows.scss';
$margin-between-screens: 10px;
$content-width: 635px + $margin-between-screens * 2;

.ComponentHelpOverlay {
  $margin-between-screens: 10px;
  $content-width: 635px + $margin-between-screens * 2;

  z-index: 1001;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: -9999px;
  width: 100%;
  height: 100%;
  display: none;
  transition: opacity 0.5s;

  > div {
    margin: 0px $margin-between-screens;
  }

  img {
    height: 300px;
    @include tablet{
      height: 30vh;
    }
  }

  h2 {
    margin-top: 120px;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 54px;
    text-align: center;
    color: $WhiteLight;
    @include mobile{
      font-size: 21px;
      line-height: 25px;
      margin-top: 25px;
      margin-bottom: 15px;
    }
    @include smallMobile{
      font-size: 16px;
      line-height: 25px;
      margin-top: 25px;
      margin-bottom: 15px;
    }
  }

  p {
    margin-top: 14px;
    margin-bottom: 84px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    text-align: justify;
    color: $WhiteLight;
    max-width: 630px;
    @include mobile{
      text-align: center;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.02em;
      max-width: 90%;
      margin: 0 auto;
    }
  }
}

.OverlayVisible {
  display: flex;
  top: 0px;
}

.Overlay {
  z-index: 101;
  position: absolute;
  background-color: $CloudBurst;
  opacity: 0.8;
  width: 100%;
  height: 100%;
}

.VisibleBlock {
  z-index: 102;
  height: 70vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Content {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ScreensContainer {
  min-height: 600px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 900px;

  position: relative;
  transition: left 400ms;

}

.ControlBetweenForms {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 60px;
  min-height: 60px;
  background-color: rgba($WhiteLight, 0.3);
  border-radius: 50%;
  margin: 0 120px 0 120px;
  @include tablet{
    display: none;
  }
}

.ControlFormsDisabled{
  cursor: default;
  svg path {
    transition: fill 0.3s;
    fill: rgba($CloudBurst, 0.7);
    @include tablet{
      fill: rgba(255, 255, 255, 0.2)
    }
  }
}

.Points {
  display: flex;
  flex-direction: row;
  align-self: center;
}

.Point {
  width: 11px;
  height: 11px;
  background-color: rgba($WhiteLight, 0.3);
  border-radius: 50%;
  margin: 0 5px;
  transition: background-color 200ms;
  @include tablet{
    width: 12px;
    height: 12px;
    margin: 0 8px;
  }
  @include mobile{
    width: 6px;
    height: 6px;
    margin: 0 4px;
  }
}

.actived {
  background-color: $WhiteLight;
  @include tablet{
    width: 42px;
    border-radius: 10px;
  }
  @include mobile{
    width: 22px;
  }
}

.Button {
  width: 200px;
}

.ScreensContainerFirst {
  left: 0;
}

.ScreensContainerSecond {
  left: 0;
}

.ScreensContainerThird {
  left: 0;
}

.ScreensContainerFourth {
  left: 0;
}

.ScreensContainerFifth {
  left: 0;
  button{
    width: 200px;
    height: 50px;
    margin: 0 auto;
    @include tablet{
      margin-top: 30px;
    }
    @include mobile{
      height: auto;
    }
  }
}

.ComponentHelpOverlayBottomMenu{
  @include tablet{
    z-index: 1010;
    top: -145px;
  }
  @include mobile{
    top: -65px;
  }
}

.sliderItem{
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  height: 50vh;
  img{
    margin: 0 auto;
  }
  button{
    margin: 0 auto;
    margin-top: 30px;
  }
}
