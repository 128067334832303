@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/functions.scss';
@import '@src/styles/windows.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @include mobile {
    gap: 18px;
    margin-bottom: 52px;
  }
}

.getPackageButton {
  background-color: $Bordo;
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: normal;
}

.title {
  @include title42Black;
  color: $CloudBurst;

  @include mobile {
    @include text28Bold;
  }
}

.downBlock {
  display: flex;
  gap: 22px;
  height: 300px;

  @include tablet {
    height: auto;
  }
}

.downBlockLeft {
  border-radius: 10px;
  background: $CloudBurst;
  padding: 32px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 298px);
  box-sizing: border-box;

  @include mobile {
    padding: 28px 16px;
    width: 100%;
  }
}

.downBlockLeftTitle {
  width: 100%;
  @include text24Bold;
  color: $WhiteLight;
  margin-bottom: 14px;
}

.downBlockLeftDescription {
  @include text16;
  color: $BaliHai;
  margin-bottom: 30px;

  @include mobile {
    margin-bottom: 42px;
  }
}

.downBlockLeftButton {
  @extend .getPackageButton;
  margin-top: 22px;
  max-width: 276px;

  @include mobile {
    max-width: 100%;
    margin-top: 28px;
  }
}

.downBlockRight {
  box-sizing: border-box;
  width: 276px;
  background: $CatskillWhite2;
  border-radius: 10px;
  padding: 31px 20px;
  display: flex;
  flex-direction: column;
  gap: 34px;

  @include mobile {
    display: none;
  }
}

.downBlockRightMobile {
  display: none;
  @include mobile {
    margin-top: 40px;
    box-sizing: border-box;
    background: $CatskillWhite2;
    width: 100%;
    height: 142px;
    padding: 18px 16px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.starsBlock {
  display: flex;
  gap: 4px;
}

.starIcon {
  width: 24px;
  height: 24px;

  @include mobile {
    width: 16px;
    height: 16px;
  }
}

.starIconFilled {
  fill: $Bordo;
}

.downBlockRightDescription {
  @include text16;
  color: $CloudBurst;

  @include mobile {
    @include text14Thin;
  }
}
