@import '@src/styles/colors.scss';
@import '@src/styles/windows.scss';

.Pop-Up {
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: -9999px;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s;

  &_visible {
    opacity: 1;
    top: 0px;
  }

  &-Overlay {
    z-index: 101;
    position: absolute;
    background-color: $Black;
    opacity: 0.3;
    width: 100%;
    height: 100%;
  }

  &-Block {
    z-index: 102;
    width: 70vw;
    height: 70vh;
    @include tablet{
      width: 100%;
      position: absolute;
      bottom: 55px;
    }

    .Header {
      display: flex;
      flex-direction: row;
      background-color: $CloudBurst;
      border-radius: 10px 10px 0px 0px;
      padding: 9px;

      .Title {
        flex-grow: 1;
        font-weight: bold;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $WhiteLilac;
        @include mobile{
          font-size: 11px;
        }
      }

      .Close-Icon {
        flex-grow: 0;
        cursor: pointer;
      }
    }

    .Content {
      height: 100%;
      background-color: $Zircon;
      border-radius: 0px 0px 10px 10px;
      padding: 13px;
      @include tablet{
        border-radius: 0;
        padding: 5px;
      }

      iframe{
        width: 100%;
        box-sizing: border-box;
      }

      & > div {
        height: 100%;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 12px;
          border-radius: 10px;
          background-color: $CatskillWhite2;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: $Ghost;
        }
      }
    }
  }
}
