@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/functions.scss';

.subnav {
  display: none;
  @media screen and (max-width: 720px) {
    display: flex;
  }
  position: fixed;
  bottom: 0;
  width: 200%;
  height: scaledPixels(150);
  align-items: center;
  z-index: 1000;
  background-color: $CardMainWhite;
  transform: translateX(0);
  transition: transform 0.7s;

  &.slide {
    transform: translateX(-50%);
  }

  &__button-container {
    box-sizing: border-box;
    width: 100%;
    height: 85%;
    padding: 1% 72px;
  }

  &__tags {
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    width: 100vw;
    justify-content: space-evenly;
    flex-shrink: 0;
    flex-grow: 0;

    .Button {
      background-color: transparent;
      color: $Waterloo;
      padding: 0;
    }
  }

  &__item {
    list-style-type: none;
    color: $Waterloo;
    width: 14%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size: scaledPixels(19);
      margin-top: scaledPixels(9);
      text-align: center;
      color: $Waterloo;
    }
  }

  &__icon {
    height: scaledPixels(60);
    width: scaledPixels(60);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.subnavHide {
  display: none;
  @media screen and (max-width: 720px) {
    display: flex;
  }
  position: fixed;
  bottom: 0;
  width: 200%;
  height: scaledPixels(150);
  align-items: center;
  z-index: 1000;
  background: transparent;
  transform: translateX(-50%);
  transition: transform 0.7s;

  &.slideAway {
    background-color: $CardMainWhite;
    transform: translateX(0);
  }

  &__button-container {
    box-sizing: border-box;
    width: 100%;
    height: 85%;
    padding: 1% 72px;
  }

  &__tags {
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    width: 100vw;
    justify-content: space-evenly;
    flex-shrink: 0;
    flex-grow: 0;

    .Button {
      background-color: transparent;
      color: $Waterloo;
      padding: 0;
    }
  }

  &__item {
    list-style-type: none;
    color: $Waterloo;
    width: 14%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size: scaledPixels(19);
      margin-top: scaledPixels(9);
      text-align: center;
      color: $Waterloo;
    }
  }

  &__icon {
    height: scaledPixels(60);
    width: scaledPixels(60);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

