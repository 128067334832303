@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/functions.scss';
@import '@src/styles/windows.scss';

.container {
  box-sizing: border-box;
  width: 100%;
  height: 520px;
  border-radius: 10px;
  background-image: url('../../../assets/images/landingGetMoney.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
  padding: 72px 66px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @include mobile {
    justify-content: space-between;
    padding: 18px 0 0;
    height: 451px;
    margin-bottom: 22px;
  }
}

.title {
  @include title42Black;
  color: $Bordo2;
  margin-bottom: 9px;

  @include mobile {
    display: none;
  }
}

.infoMobileBlock {
  display: none;

  @include mobile {
    margin-left: 21px;
    display: block;
  }
}

.infoTitleMobile {
  @include title32Bold;
  color: $Bordo2;
}

.infoDescriptionMobile {
  @include text22Bold;
  color: $CloudBurst;
}

.description {
  @include text18Bold;
  color: $Black;
  margin-bottom: 84px;

  @include mobile {
    display: none;
  }
}

.button {
  background-color: $Bordo;
  width: 328px;
  height: 60px;

  @include mobile {
    width: 100%;
  }
}
