@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/windows.scss';

.Button {
  display: flex;
  justify-content: center;
  align-items: center;

  white-space: nowrap;

  position: relative;

  background-color: $Midnight;
  color: $WhiteLight;
  padding: 8px 24px;
  border-radius: 25px;
  transition: 0.3s;
  box-sizing: border-box;


  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  font-size: 14px;

  &_clicked {
    cursor: pointer;
  }

  &_heightFull {
    height: 100%;
  }

  &_status {

    &_standard {
      background-color: $CatskillWhite2;
      color: $CloudBurst;
    }

    &_new {
      background-color: $CloudBurst;
      border-radius: 6px;
      padding: 14px 40px;
      @include text18;
      font-weight: 700;
      line-height: 22px;
      color: $WhiteBase;

      &-pink {
        @include font;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        background-color: $Mandy;
        color: $WhiteDark;
        border-radius: 6px;
        padding: 14px 25px;
      }
    }

    &_header {
      @include font;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: $WhiteDark;
      background-color: $CloudBurst;
      border-radius: 4px;
      padding: 8px 25px;
    }

    &_smoothed {
      background-color: $RockBlue;

      &:hover{
        background-color: #cbdeed;
      }
    }

    &_disabled {
      cursor: default;
      background-color: $RockBlue;
    }

    &_actived {
      background-color: $FrenchRose;
      transition: .3s all;

      &:hover{
        background-color: #F992AA;
      }
    }

    &_completed {
      background-color: transparent;
      border: 1px solid #AFC3D5;
    }
  }

  &-Content {
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition: opacity 0.3s;
    text-align: center;
    white-space: nowrap;

    &_isLoading {
      opacity: 0;
    }
  }

  &-Loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;

    &_isLoading {
      opacity: 1;
    }
  }
}
