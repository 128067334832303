@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/functions.scss';
@import '@src/styles/windows.scss';

.container {
  width: 100%;
  margin-top: 8px;
}

.customTrack {
  touch-action: none;
  background: $SantasGray;
  height: 6px;
  border-radius: 3px;
  position: relative;
  z-index: 1;
}

.customActiveTrack {
  background: $Bordo;
  height: 6px;
  border-radius: 3px;
}

.customSlider {
  touch-action: none;
  width: 20px;
  height: 20px;
  background: $WhiteBase;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
  transition: transform 0.2s ease;
  z-index: 2;
  cursor: pointer;
  position: absolute;
  bottom: -6px;
  left: -6px;
}

.customSlider:hover {
  transform: scale(1);
}

.valueLabel {
  color: $WhiteBase;
  @include text16;
  position: absolute;
  bottom: 18px;
  left: -1.7vw;
  white-space: nowrap;
  background: $CloudBurst;
  padding: 1px 2px;
  border-radius: 2px;
}

.customLabel {
  display: none;
}

.rangeLabels {
  width: inherit;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.rangeLabel {
  margin-top: 8px;
  @include text14;
  color: $GullGray;
}

.dectRangeBlock {
  margin-bottom: 18px;
}

.miniTitle {
  @include text18Bold;
  color: $CloudBurst;
}

.amountDescription {
  @include title28;
  color: $CloudBurst;
}
