@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/functions.scss';
@import '@src/styles/windows.scss';
@import '@src/styles/constants.scss';

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 940px) {
    flex-direction: column;
    gap: 20px;
  }

  @include mobile {
    margin: 12px 0 52px;
  }
}

.title {
  width: 574px;
  @include text26Bold;
  color: $CloudBurst;

  @media screen and (max-width: 940px) {
    width: 100%;
  }

  @include tablet {
    @include text20Bold;
  }

  @include mobile {
    @include text16Bold;
  }
}

.downBlock {
  display: flex;
  width: 50%;
  justify-content: space-between;

  @media screen and (max-width: 940px) {
    width: 100%;
    justify-content: space-around;
  }
}

.image {
  width: var(--width);

  @include mobile {
    width: var(--mobileWidth);
  }
}
