@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/functions.scss';
@import '@src/styles/windows.scss';
@import '@src/styles/constants.scss';


.container {
  width: 100%;
  overflow: hidden;

  @include mobile {
    width: 100vw;
    margin-left: -16px;
  }
}

.getPackageButton {
  background-color: $Bordo;
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: normal;
}

.upBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: inherit;
  margin-bottom: 40px;

  @include mobile {
    margin-bottom: 0;
  }
}

.commonTitle {
  @include title42Black;
  color: $CloudBurst;

  @include mobile {
    @include text28Bold;
  }
}

.commonTitleWithMargin {
  @extend .commonTitle;
  @include mobile {
    margin: 0 16px 18px;
  }
}

.button {
  @extend .getPackageButton;
  max-width: 276px;

  @include mobile {
    display: none;
  }
}

.goodServiceButton {
  @extend .getPackageButton;
  max-width: 276px;

  @include mobile {
    display: none;
  }
}

.downBlock {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 22px;

  @media screen and (max-width: 1120px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
  }

  @include mobile {
    display: none;
  }
}

.downBlockMobile {
  display: none;

  @include mobile {
    display: block;
  }
}

.item {
  background: $CatskillWhite2;
  border-radius: 10px;

  @include mobile {
    position: relative;
    max-width: 100% !important;
    height: 456px;
    border: 1px solid $CasperLight;
  }
}

.itemUpBlock {
  display: flex;
  justify-content: space-between;
}

.itemTitle {
  @include text24Bold;
  color: $CloudBurst;
  margin: 52px 0 47px 20px;
}

.itemDescription {
  color: $CloudBurst;
  @include text15;
  margin: 0 20px 12px;
}

.mobileButton {
  @extend .getPackageButton;
  display: none;

  @include mobile {
    display: flex;
    position: absolute;
    width: calc(100% - 32px);
    bottom: 22px;
    left: 16px;
  }
}

:root {
  --product-width: 356px;
  --betwen-products: 49px;
  --main-cell-height: 182px;
}

@media screen and (max-width: 1220px) {

  :root {
    --betwen-products: 15px;
  }
}
@media screen and (max-width: 720px) {

  :root {
    --main-cell-height: #{scaledPixels(322)}
  }
}


.component-products {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  padding-top: 79px;
  //min-height: 803px;
  overflow-x: hidden;
  width: 100%;
  min-height: unset;

  @include media(720px) {
    min-height: auto;
    padding-top: scaledPixels(61);
  }

  &__title {

    @include media(1300px) {
      margin-left: 74px;
      font-weight: 800;
      font-size: 50px;
      line-height: 60px;
    }

    &__mobile {
      display: none;
      margin-left: 24px;
    }
    @include media(720px) {
      display: none;

      &__mobile {
        display: block;
      }
    }
  }

  h2 {
    margin-bottom: 63px;
    font-size: 60px;
    line-height: 72px;
    font-weight: 800;
    color: $CloudBurst;

    @include media(1300px) {
      margin-left: 74px;
      font-weight: 800;
      font-size: 50px;
      line-height: 60px;
    }

    @include media(720px) {
      font-weight: 800;
      font-size: 24px;
      line-height: 29px;
      margin-left: 24px;
    }
  }

  &__content-block {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    position: relative;

    left: 0;
    transition: left 500ms;

    &_mobile {
      display: none;
    }

    @include media(720px) {
      display: none;

      &_mobile {
        display: block;
      }
    }

    &.payment-deferment {
      left: calc((var(--product-width) * -1) - var(--betwen-products));

      @include media(1150px) {
        left: calc(((100vw - 83px) / -3) - var(--betwen-products));
      }
    }

    //&.payment-deferment {
    //  left: calc(((var(--betwen-products) * -1) - var(--product-width)) * 2);
    //
    //  @include media(1150px) {
    //    left: calc(((var(--betwen-products) * -1) - ((100vw - 83px) / 3)) * 2);
    //  }
    //}

    &__item {
      display: flex;
      flex-direction: row;
      height: 508px;

      margin-left: var(--betwen-products);

      @include media(1150px) {
        height: 600px;
      }

      @include media(881px) {
        height: 651px;
      }

      &:first-child {
        margin-left: 0;
      }


      &__product-block {
        z-index: 50;
        position: relative;
        background-color: $CatskillWhite4;
        box-sizing: border-box;
        padding: 40.8px 27.25px 62px 27.25px;
        width: var(--product-width);
        min-width: var(--product-width);
        border: 1.1px solid $CatskillWhite3;
        border-radius: 10px;
        display: flex;
        flex-direction: column;

        @include media(1150px) {
          width: calc((100vw - 83px) / 3);
          min-width: calc((100vw - 83px) / 3);
        }

        @include media(881px) {
          display: flex;
          flex-direction: column;
        }

        @include media(720px) {
          width: auto;
          min-width: auto;
        }

        &__icon {
          height: 130px;
          margin-right: auto;
          margin-bottom: 47.7px;

          @include media(881px) {

            & + div {
              flex: 1
            }
          }
        }

        h3 {
          font-weight: 900;
          font-size: 36px;
          line-height: 43px;
          color: $CloudBurst;
          margin-bottom: 18.35px;
        }

        p {
          min-height: 110px;
          font-weight: normal;
          font-size: 15px;
          line-height: 18px;
          color: $ShuttleGray;
          white-space: pre-line;
        }

        &__button-block {
          width: 117px;
          height: 34px;

          &__text {
            color: $CatskillWhite2;
          }

          .Button {
            z-index: 100;
            position: relative;
            padding: 7px 18px;
            box-sizing: border-box;
            height: 100%;
            font-size: 15px;
            line-height: 18px;
            color: $WhiteDark;

            &_status {

              &_actived {
                background-color: $BrinkPink;
              }

              &_smoothed {
                background-color: $Casper;

                & + .Right-Arrow {
                  background-color: $Casper;
                  transform: scale(-1);
                  margin-left: -20px;
                }
              }
            }
          }

          .Right-Arrow {
            z-index: 99;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 34px;
            height: 100%;
            top: -34px;
            left: 30px;
            transition: left 500ms;

            background-color: $BrinkPink;
            border-radius: 50%;

            img {
              height: 9.65px;
            }

            @media (hover: hover) {

              &-entered {
                left: calc(117px + 7px);
              }
            }
          }
        }
      }

      &__graph-block {
        $graphBlockRoot: &;
        box-sizing: border-box;
        height: 100%;
        width: 0;
        transition: width 500ms, padding-left 500ms;
        background-color: transparent;
        border: 1.1px solid transparent;
        border-radius: 10px;

        z-index: 49;
        position: relative;
        padding-left: 0;
        left:calc(var(--product-width) * -1);
        overflow-x: hidden;

        @include media(1150px) {
          left: calc((100vw - 83px) / -3)
        }

        &__content-block {
          box-sizing: border-box;
          padding: 49px 79px 62px 85px;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;

          &__text {
            font-weight: normal;
            font-size: 15px;
            line-height: 18px;
            color: $CloudBurst;
            overflow: hidden;
            margin-top: 24.5px;
            flex: 1;

            &_active {
              transition: color 2500ms;
              color: $CatskillWhite2;
            }
          }

          &__table-content {
            $rootTableContent: &;
            border-collapse: collapse;
            width: 474px;
            position: relative;
            margin: 0 auto;

            @include media(836px) {
              width: 100%;
            }

            tr#{&}__with-border {
              border-bottom: 1.4px solid $WhiteDark;
            }

            td {

              &#{$rootTableContent}__with-border {
                border-right: 1.4px solid $WhiteDark;
              }

              &#{$rootTableContent}__min-height > div {
                min-height: calc(var(--main-cell-height) / 8);
              }

              &#{$rootTableContent}__max-width {
                width: calc(var(--main-cell-height) / 8);
              }
            }

            &__main-cell {
              height: var(--main-cell-height);
              display: flex;
              flex-direction: row;
              align-items: flex-end;
              justify-content: space-evenly;

              &__pillar {
                width: 18px;
                transition: height 500ms;
                height: 0;
                border-top: 0.7px solid $WhiteBase;
                border-left: 0.7px solid $WhiteBase;
                border-right: 0.7px solid $WhiteBase;
                background-color: rgba(253, 253, 253, 0.2);
                margin-bottom: -2px;

                @include media(720px) {
                  width: 8.5%;
                }

                &_92 {
                  height: 92%;
                }

                &_77 {
                  height: 77%;
                }

                &_61 {
                  height: 61%;
                }

                &_14 {
                  height: 14%;
                }

                &_2 {
                  height: 2%;
                }

              }
            }

            &__arrow-block {
              $arrowBlockPath: &;
              width: 100%;

              &__arrow {
                position: relative;
                width: 8px;
                height: 8px;
                box-sizing: border-box;

                &_top {
                  transform: rotate(-45deg);
                }

                &_right {
                  transform: rotate(45deg);
                }

                &::before {
                  content: '';
                  width: 100%;
                  height: 100%;
                  border-width: 1.4px 1.4px 0 0;
                  border-style: solid;
                  border-color: $Alabaster;
                  display: block;
                }
              }

              &_top {
                height: var(--main-cell-height);

                #{$arrowBlockPath}__arrow {
                  right: calc(var(--main-cell-height) / -8 + 3px);
                }
              }

              &_right {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                #{$arrowBlockPath}__arrow {
                  top: calc((var(--main-cell-height) / -8) + 9px);

                  @include media(720px) {
                    width: scaledPixels(13);
                    height: scaledPixels(13);
                    transform: rotate(45deg) translate(-50%, -50%);
                    top: calc((var(--main-cell-height) / -8) + 3.8vw);

                    @include media(500px) {
                      top: calc((var(--main-cell-height) / -8) + 3.5vw);
                    }
                  }
                }
              }
            }

            &__tier {
              position: absolute;
              right: 0;
              left: calc(var(--main-cell-height) / 8 + 2px);
              height: 1px;
              background-color: $PickledBluewood;
              z-index: -1;
              @media (min-width: 721px) {
                visibility: hidden;
              }

              &_1 {
                top: 6.73%;
              }

              &_2 {
                top: calc(6.73% + 13.46%);
              }

              &_3 {
                top: calc(6.73% + 2 * 13.46%);
              }

              &_4 {
                top: calc(6.73% + 3 * 13.46%);
              }

              &_5 {
                top: calc(6.73% + 4 * 13.46%);
              }

              &_6 {
                top: calc(6.73% + 5 * 13.46%);
              }
            }

            &.standard {
              #{$rootTableContent}__main-cell {

                & > * {

                  &:nth-child(n + 6) {
                    display: none;
                  }
                }
              }
            }

            &.percentage {
              #{$rootTableContent}__main-cell {

                & > * {

                  &:nth-child(-n + 4) {
                    display: none;
                  }
                }
              }
            }

            &.payment-deferment {
              #{$rootTableContent}__main-cell {

                & > * {

                  &:nth-child(n + 6) {
                    display: none;
                  }
                }
              }
            }
          }

          &__buttons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;

            .Button_status {

              &_actived {
                background-color: $Mandy;

                &:hover {
                  background-color: $TickleMePink;
                }
              }
            }
          }
        }

        &-enter-active, &-enter-done {
          width: $content-width;
          padding-left: var(--product-width);
          border-color: $CatskillWhite3;
          background-color: $CloudBurst;

          @include media(1220px) {
            width: 95.9vw;
          }
          @include media(1150px) {
            width: 94.9vw;
            padding-left: calc((100vw - 77px) / 3);
          }
          @include media(870px) {
            width: 93.9vw;
          }
        }

        &-enter-done {
          #{$graphBlockRoot}__content-block__table-content__tier {
            visibility: visible;
          }


        }
      }
    }
  }

  &__content-title-wrap {
    flex: 1
  }

  &__carousel {

    margin-left: scaledPixels(-72);
    margin-right: scaledPixels(-72);

    padding-bottom: scaledPixels(98);

    .slick-dots {
      bottom: 3.5vw;

      li {
        height: auto;
        width: auto;
        margin: 0;

        button {
          width: scaledPixels(12);
          height: scaledPixels(12);
          box-sizing: content-box;
          position: relative;
          padding: scaledPixels(10);

          &:before {
            transition: .3s;
            width: scaledPixels(12);
            height: scaledPixels(12);
            font-size: 0;
            border-radius: scaledPixels(6);
            background-color: $Casper;
            opacity: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%)
          }
        }

        &.slick-active {

          button {
            width: scaledPixels(42);

            &:before {
              background-color: #AEC2D4;
              width: scaledPixels(42);
            }
          }
        }
      }
    }

    &__item {
      $sliderItemPath: &;
      width: scaledPixels(576) !important;
      position: relative;

      @mixin button {
        font-size: scaledPixels(24);
        width: scaledPixels(230);
        height: scaledPixels(60);
        font-weight: bold;
        background-color: $BrinkPink;
        border-radius: scaledPixels(30);
        color: $WhiteDark;
        text-align: center;
        line-height:scaledPixels(60);
      }

      &__short {
        padding: scaledPixels(43);
        background-color: $CatskillWhite3;
        border-radius: scaledPixels(14);
        transition: .3s;
        position: absolute;
        left: 0;
        top: 0;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        box-sizing: border-box;
        z-index: 111;
        display: flex;
        flex-direction: column;
        margin-left: -1px;
        margin-top: -1px;
        overflow: hidden;

        @at-root #{$sliderItemPath}_opened #{&} {
          height: scaledPixels(148.5);
        }

        &__icon {
          margin-top: scaledPixels(73);
          margin-bottom: scaledPixels(73);
          height: scaledPixels(281);
          opacity: 1;
          position: relative;
          transition: .3s;
          width: max-content;

          @at-root #{$sliderItemPath}_opened #{&} {
            visibility: hidden;
            position: absolute;
            opacity: 0;
          }

          @media (max-width: 720px) {
            width: 100%;
            max-width: 52%;
            height: auto;
          }
        }

        &__title {
          font-size: scaledPixels(52);
          line-height: scaledPixels(62);
          font-weight: 800;
          position: relative;

          &__icon {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            display: none;
            padding: scaledPixels(17) scaledPixels(14);
            width: scaledPixels(22);
            height: scaledPixels(16);
            background-color: $CasperLight;
            border-radius: 50%;

            @at-root #{$sliderItemPath}_opened #{&} {
              display: block;
            }
          }
        }

        &__text {
          font-size: scaledPixels(25);
          line-height: scaledPixels(30);
          color: $ShuttleGray;
          margin-bottom: scaledPixels(102);
          margin-top: scaledPixels(19);
          white-space: pre-line;
          flex: 1;

          @at-root #{$sliderItemPath}_opened #{&} {
            display: none;
          }
        }

        &__button {
          @include button;

          @at-root #{$sliderItemPath}_opened #{&} {
            display: none;
          }
        }
      }

      &__details {
        height: scaledPixels(822.5);
        border-radius: scaledPixels(15);
        overflow: hidden;
        background-color: $CloudBurst;
        padding-top: scaledPixels(148.5);

        &__text {
          @at-root p#{&} {
            line-height: scaledPixels(25);
            font-size: scaledPixels(21);
            color: $CatskillWhite2;
          }
        }

        &__graph-block {
          left: 0;
          width: 100%;

          &__content-block {
            padding: scaledPixels(25) scaledPixels(45) scaledPixels(50);

            .Button {
              @include media(720px) {
                padding-top: 0;
                padding-bottom: 0;
                font-size: scaledPixels(24);
                line-height: scaledPixels(60);
                width: scaledPixels(230);
                height: scaledPixels(60);
              }
            }
          }
        }

        &__button {
          @include button;
        }
      }
    }

    .slick-slide {
      transform: scale(0.81);
      transition: .3s;

      .component-products__carousel__item {
        transform: translateX(#{scaledPixels(27.28)});
        margin-bottom: 0;
      }

      &.slick-active {
        transform: scale(1);

        & ~ .slick-slide {

          .component-products__carousel__item {
            transform: translateX(#{scaledPixels(-27.28)});
          }
        }

        .component-products__carousel__item {
          transform: translateX(0);
        }
      }
    }
  }
}
