@import '@src/styles/colors.scss';
@import '@src/styles/windows.scss';

.FooterBlock,
.FooterBlockBg,
.FooterBlockDefault{
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  width: 100%;
  margin-top: 40px;
  box-sizing: border-box;

  a, img {
    height: 100%;
  }
}

.LeftSection, .RightSection {
  display: flex;
  align-items: center;
  height: 100%;

  a, span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: $WhiteLight;
  }
}

.ChatText {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #FDFDFD;
  @include tablet{
    font-size: 28px;
  }
  @include mobile{
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
  }
}

.RightSection {
  @include tablet{
    width: 100%;
    justify-content: space-between;
  }
  @include mobile{
    display: block;
  }

  a {
    margin-right: 10px;

    &:last-of-type {
      margin-right: 0px;
    }
  }
}

.LeftSection {
  @include tablet{
    display: none;
  }

  a {
    margin-right: 43px;

    &:last-of-type {
      margin-right: 0px;
    }
  }
}

.DivLine {
  margin-left: 36px;
  margin-right: 42px;
  @include tablet{
    display: none;
  }
}

.JivoBlock{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #FDFDFD;
  height: 38px;
  width: 140px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #55677A;
  border-radius: 10px 10px 0 0;
  margin-left: 25px;
  margin-right: 15px;
  transition: .3s;
  &:hover{
    background: #7E94A7;
  }
  @include mobile{
    border-radius: 10px 10px 10px 0;
    margin-left: 0;
    margin-right: 10px;
  }
}

.FooterBlockBg{
  background: #1c2a40;
}

.InnerFooter {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  @include tablet{
    margin-bottom: 69px;
  }
  @include mobile{
    margin-bottom: 32px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.wrapper{
  display: flex;
  @include mobile{
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
  }
}
