@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/functions.scss';
@import '@src/styles/windows.scss';

.container {
  width: 100%;

  @include mobile {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 52px;
  }
}

.upBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: inherit;
  margin-bottom: 40px;

  @include mobile {
    margin-bottom: 0;
  }
}

.title {
  @include title42Black;
  color: $CloudBurst;

  @include mobile {
    @include text28Bold;
  }
}

.getPackageButton {
  background-color: $Bordo;
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: normal;
}

.button {
  @extend .getPackageButton;
  max-width: 276px;

  @include mobile {
    display: none;
  }
}

.buttonMobile {
  @extend .getPackageButton;
  display: none;
  @include mobile {
    display: flex;
  }
}

.downBlock {
  width: 100%;
  display: grid;
  grid-template-rows: repeat(2, 300px);
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 22px;

  @include tablet {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 300px);
  }

  @media screen and (max-width: 836px) {
    grid-gap: 16px;
  }

  @include mobile {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 208px);
  }
}

.downBlockDeskTop {
  @extend .downBlock;

  @include mobile {
    display: none;
  }
}

.downBlockMobile {
  @extend .downBlock;
  display: none;

  @include mobile {
    display: grid;
  }
}

.item {
  overflow: hidden;
  background: $CatskillWhite2;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.itemTitle {
  text-align: center;
  padding: 37px 20px 0;
  z-index: 1;
  @include text20Bold;

  @include mobile {
    padding: 15px 16px 0;
    @include text15Bold;
  }
}

.itemImage {
  position: absolute;
}
