@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/functions.scss';
@import '@src/styles/windows.scss';

.component-header {
  position: fixed;
  width: 100%;
  height: 70px;
  background-color: #F2F4F9;
  z-index: 1000;
  border-bottom: 1px solid $CatskillWhite3;

  @media screen and (max-width: 720px) {
    position: unset;
    height: scaledPixels(70);
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .Content-Block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;

    .logo {
      display: flex;

      &__main {
        height: 40px;
        @media screen and (max-width: 720px) {
          height: scaledPixels(60);
          width: auto;
        }
      }

      &__text {
        height: 26px;
        padding: 7px 15px;
        @media screen and (max-width: 991px) {
          display: none;
        }
        @media screen and (max-width: 720px) {
          display: block;
          height: scaledPixels(39);
          padding: scaledPixels(10.5) scaledPixels(15);
        }
      }
    }

    .icon{
      display: flex;
    }

    .auth-icons {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .profile-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 8.5px;

      border-right: 1px solid $CloudBurst;

      .Icon {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 31.4px;
        }
      }
    }

    .logout-block{
      @include smallMobile{
        margin-left: 0;
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 31.4px;
        height: 31.4px;
        padding-right: 1px;
        background-color: $EbonyClay;
        border-radius: 50%;
        margin-right: 8px;

        img {
          width: 16px;
        }
      }
    }

    .Button {

      .icon {
        display: none;
      }

      @media screen and (max-width: 720px) {
        padding: 0;
        background-color: transparent;

        .text {
          display: none;
        }

        .icon {
          display: flex;

          svg {
            width: scaledPixels(60);
            height: scaledPixels(60);
          }
        }
      }
    }
    .Button_status_actived{
      @include tablet{
        svg{
          path{
            fill: #F76083;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {

  .text {
    font-size: 10px !important;
    display: none;
  }
}

.Component-Header-Reg {

  border-bottom: 1px solid #E4E8F2;

  .Auth-Icons {
    display: flex;
    align-items: center;
  }

  .Width-Wrapper{
    height: 70px;
    width: 100%;
    padding: 0 35px;
    max-width: 1240px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }

  img{
    width: 259px;
    height: 40px;
  }
  @include tablet{
    display: none;
  }

  button{
    background-color: #9db7cb;
  }

  .Logout-Block{
    width: 120px;
    height: 32px;
    background: #939BB3;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FDFDFD;
  }

  .Exit-Button{
    width: 120px;
    height: 32px;
    background: $CloudBurst;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FDFDFD;
    margin-left: 20px;
  }
}

.buttons-block{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #EBEEF5;
}

.buttons-container {
  display: flex;
}

.Content-Top {
  .logout-block {
    background-color: $EbonyClay;
  }
}

.auth-icons-openModal {
  .logout-block {
    background-color: $EbonyClay;
  }
}

.buttons-block {
  .logout-block {
    background-color: $EbonyClay;
  }
}

.logout-block {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  padding: 7px 15px;
  //margin-right: 25px;
  //background-color: $EbonyClay;

  .text-button {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FDFDFD;
  }
}

.logout-block__top{
  background-color: transparent;
  padding: 0;
}

.Back-to-reg{
  font-weight: 600;
  background: #939BB3;
  border-radius: 16px;
  padding: 7px 15px;
  font-size: 14px;
  line-height: 17px;
  color: #FDFDFD;
  cursor: pointer;
  margin-right: 8px;
}

.close-bottom-block{
  position: relative;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:before{
    content: '';
    position: absolute;
    background: #272F3C;
    width: 15px;
    height: 1px;
    transform: rotate(45deg);
  }
  &:after{
    content: '';
    position: absolute;
    background: #272F3C;
    width: 15px;
    height: 1px;
    transform: rotate(-45deg);
  }
}
