@import '@src/styles/windows.scss';

.slick-slide {
  transform: scale(.81);
  transition: .3s;
}

.slick-slide.slick-active {
  transform: scale(1);
}

//.slick-list {
//  overflow: inherit;
//}

.slick-dots li.slick-active button{
  width: 42px;

  @include mobile {
    width: 6px;
  }
}

.slick-dots li.slick-active button:before {
  width: 42px;
  opacity: 1;
  background-color: #1C2A40;
  @include mobile{
    width: 6px;
  }
}

.slick-dots{
  bottom: -40px;

  li{
    height: auto;
    width: auto;
    margin: 0;

    button{
      margin-left: 0;
      width: 12px;
      height: 12px;
      box-sizing: content-box;
      position: relative;

      &:before{
        background-color: #AEC2D4;
        transition: .3s;
        width: 12px;
        height: 12px;
        font-size: 0;
        border-radius: 12px;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        @include mobile {
          width: 6px;
          height: 6px;
        }
      }
      @include mobile{
        width: 6px;
        height: 6px;
      }
    }
  }
  @include mobile {
    bottom: -20px;
  }
}
